<template>
    <footer class="footer">
        <div class="container">
            <div class="footer-section">
                <h4>Ibadah</h4>
                <ul>
                    <li>Ibadah Umum</li>
                    <li>Usia Emas</li>
                    <li>Teens</li>
                    <li>Youth</li>
                    <li>Pria Bethel Ibadah</li>
                    <li>Wanita Bethel Ibadah</li>
                    <li>Child</li>
                </ul>
            </div>
            <div class="footer-section gallery">
                <h4>Galeri</h4>
                <div class="gallery-images">
                    <img src="../assets/gf1.jpg" alt="Gambar 1"/>
                    <img src="../assets/gf8.jpg" alt="Gambar 2"/>
                    <img src="../assets/gf3.jpg" alt="Gambar 3"/>
                    <img src="../assets/gf4.jpg" alt="Gambar 4"/>
                    <img src="../assets/gf5.jpg" alt="Gambar 5"/>
                    <img src="../assets/gf6.jpg" alt="Gambar 6"/>
                    <img src="../assets/gf2.jpg" alt="Gambar 7"/>
                    <img src="../assets/gf7.jpg" alt="Gambar 8"/>
                    <img src="../assets/gf9.jpg" alt="Gambar 9"/>
                </div>
            </div>
            <div class="footer-section">
                <h4>Home</h4>
                <ul>
                    <li><router-link to="/Gms" class="footer-link">Graha Mawar Saron</router-link></li>
                    <li><router-link to="/Msw" class="footer-link">Ms Worship</router-link></li>
                    <li><a class="footer-link" href="https://ibadah.gbimscic.com" target="_blank" @click="hideNavbar">Ibadah & Komsel</a></li>
                    <li><router-link to="/Media" class="footer-link">Media</router-link></li>
                    <li><router-link to="/Contact" class="footer-link">Contact</router-link></li>
                </ul>
            </div>
            <div class="footer-section">
                <h4>Persembahan</h4>
                <ul>
                    <li>BCA</li>
                    <li class="text" @click="copyToClipboard('2280279181')">2280279181</li>
                    <li class="word">GBI Mawar Saron Citra Indah City</li>
                    <li>BRI</li>
                    <li class="text" @click="copyToClipboard('4060824105')">4060824105</li>
                    <li class="word">Torkis Sariaman Nababan</li>
                </ul>
            </div>
        </div>
            <p>&copy; 2024 MS Creative | All rights reserved.</p>
            <div class="footer-logos"> 
                <a href="https://www.facebook.com/gbimawarsaron.citraindah/" target="_blank">
                    <i class="bi bi-facebook"></i>
                </a>
                <a href="https://www.youtube.com/@mawarsaroncitratv7584" target="_blank">
                    <i class="bi bi-youtube"></i>
                </a>
                <a href="https://www.instagram.com/gbi_mscic.official/#">
                    <i class="bi bi-instagram"></i>
                </a>
                <a href="https://www.tiktok.com/@gbimscic">
                    <i class="bi bi-tiktok"></i>
                </a>
                <a href="https://wa.me/081385243200">
                    <i class="bi bi-whatsapp"></i>
                </a>
                <a href="mailto:gbimscic@gmail.com">
                    <i class="bi bi-envelope"></i>
                </a>
            </div>
    </footer>
</template>

<script>
export default{
    name: "MyFooter",
    methods: {
        copyToClipboard(accountNumber) {
            navigator.clipboard.writeText(accountNumber)
            .then(() => {
                alert(`Nomor rekening ${accountNumber} telah disalin ke clipboard!`);
            })
            .catch(err => {
                console.error('Gagal menyalin teks: ', err);
            });
        }
    },
};
</script>

<style scoped>
.container{ 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
} 

.footer {
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(183, 28, 28, 0.9)); 
    color: rgba(255,255,255,0.6);
    padding: 20px;
    text-align: center;
}

.footer-section {
    flex: 1;
    margin: 70px;
    text-align: left;
}

.footer-section h4 {
    margin-bottom: 30px;
}

.footer-section ul{
    list-style-type: none;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

.footer-section ul li {
    margin-bottom: 20px;
}

.footer-link { 
    text-decoration: none;
    color: inherit;
    transition: border-bottom 0.3s;
}

.footer-link:hover { 
    text-decoration: underline;
    color: white;
}

.text {
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: bold;
    margin-top: -20px;
    cursor: pointer;
}
.word {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    margin-top: -20px;
}

.gallery-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.gallery-images img{
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.footer-logos a {
    color: rgba(255,255,255,0.6);
    margin-left: 15px;
    font-size: 1.1rem;
}

.footer-logos a:hover {
    color: white;
}

@media (max-width: 768px) { 
    .footer-section { 
        flex: 1 1 100%;
        margin: 20px 0;
    }

    .footer-section h4 { 
        margin-bottom: 15px;
    }

    .footer-section ul li { 
        margin-bottom: 10px;
    }

    .gallery-images { 
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }

    .text { 
        font-size: 24px;
    }

    .word { 
        font-size: 13px;
    }
}
</style>