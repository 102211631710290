<template>
    <div class="container">
        <h4 class="text-center mb-4">Persembahan Kasih</h4>
        <div class="row">
            <div class="col-md-4 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <h5 class="card-title text-danger">Persembahan, Persepuluhan, Ucapan Syukur</h5>
                        <ul class="list-unstyled">
                            <li>
                                <span class="bank-name">BCA </span>
                                <strong class="account-number" @click="copyToClipboard('2280279181')">2280279181</strong>
                            </li>
                            <span class="account-name">A/N GBI Mawar Saron Citra Indah City</span>
                            <li>
                                <span class="bank-name">BCA </span>
                                <strong class="account-number" @click="copyToClipboard('4060824105')">4060824105</strong>
                            </li>
                            <span class="account-name">A/N Torkis. Sariaman Nababan</span>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <h5 class="card-title text-danger">Pembangunan Gereja</h5>
                        <ul class="list-unstyled">
                            <li>
                                <span class="bank-name">BCA </span>
                                <strong class="account-number" @click="copyToClipboard('2280279199')">2280279199</strong>
                            </li>
                            <span class="account-name">A/N GBI Mawar Saron Citra Indah City</span>
                            <li>
                                <span class="bank-name">BRI </span>
                                <strong class="account-number" @click="copyToClipboard('038401001285564')">038401001285564</strong>
                            </li>
                            <span class="account-name">A/N Yayasan Mawar Saron Citra Indah</span>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <h5 class="card-title text-danger">Sahabat Orang Berduka (SOB)</h5>
                        <ul class="list-unstyled">
                            <li>
                                <span class="bank-name">BCA </span>
                                <strong class="account-number" @click="copyToClipboard('2280330321')">2280330321</strong>
                            </li>
                            <span class="account-name">A/N GBI Mawar Saron Citra Indah City</span>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4">
                <div class="card-p bg-danger text-white shadow-sm border-0">
                    <div class="card-body p-4">
                        <h5 class="card-title text-uppercase mb-3 font-weight-bold">Penting Untuk Diperhatikan!</h5>
                        <p class="card-text">Bagi bapak/ibu yang ingin memberikan persembahan/perpuluhan lewat transfer rekening, mohon untuk dapat menuliskan lengkap dengan keterangan sehingga bagian keuangan gereja dapat mengatur sesuai dengan peruntukannya.</p>
                        <div class="alert alert-danger mt-3" role="alert">
                            Informasi ini sangat penting, harap diperhatikan!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyPersembahan',
    methods: {
        copyToClipboard(accountNumber) {
            navigator.clipboard.writeText(accountNumber)
            .then(() => {
                alert(`Nomor rekening ${accountNumber} telah disalin ke clipboard!`);
            })
            .catch(err => {
                console.error('Gagal menyalin teks: ', err);
            });
        }
    },
}
</script> 

<style scoped>
.text-center {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color:#dc3545;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 20px 0;
}
.container { 
    margin-top: 30px;
}

.card {
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: scale(1.05);
}

.bank-name {
    font-size: 1rem;
    font-weight: 500;
    color: #343a40;
}

.account-number {
    font-size: 1.5rem;
    font-weight: bold;
    color: #f74b4b;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    cursor: pointer;
}

.account-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    color: #6c757d;
    display: block;
    margin-top: 5px;
}

.card-p {
    border-radius: 3px;
}

.bg-danger:hover{
    transform: none;
}

.alert {
    border-radius: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
}

h5 {
    font-size: 1.2rem;
}

p, li {
    font-size: 1rem;
}

.card-title {
    font-weight: 700;
}

.text-danger {
    color: #880606 !important;
}
</style>