<template>
    <div class="contact">
        <div class="container mt-5">
            <h2 class="text-center mb-4">Formulir Layanan</h2>
            <div class="description-container mb-4">
                <p class="description">Gereja Mawar Saron menyediakan layanan untuk membantu jemaat dalam berbagai kebutuhan spiritual dan pelayanan. Melalui formulir ini, jemaat dapat mendaftar untuk mendapatkan konseling rohani dari pendeta dan pemimpin gereja, serta berpatisipasi dalam berbagai pelayanan gereja seperti multimedia, pemusik, paduan suara dan lainnya. Kami mengundang Bapak/Ibu untuk mengisi formulir sesuai kebutuhan dan siap melayani Bapak/Ibu dengan sepenuh hati.</p>
            </div>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" :class="{ active: currentTab === 'konseling' }" @click="currentTab = 'konseling'">Layanan Konseling</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :class="{ active: currentTab === 'pelayanan' }" @click="currentTab = 'pelayanan'">Form Pelayanan</a>
                </li>
            </ul>
            <div v-if="currentTab === 'konseling'">
                <form @submit.prevent="submitKonselingForm">
                    <div class="mb-3">
                        <label for="namaLengkap" class="form-label">Full Name</label>
                        <input type="text" class="form-control" id="namaLengkap" v-model="FormData.namaLengkap" placeholder="Nama Lengkap" required>
                    </div>
                    <div class="mb-3">
                        <label for="alamatKonseling" class="form-label">Address</label>
                        <input type="text" class="form-control" id="alamatKonseling" v-model="FormData.alamat" placeholder="Alamat" required>
                    </div>
                    <div class="mb-3">
                        <label for="topikKonseling" class="form-label">Counseling Topic</label>
                        <select class="form-select" id="topikKonseling" v-model="FormData.topikKonseling" required>
                            <option value="" disabled selected>Pilih Topik Konseling</option>
                            <option value="spiritual">Spiritual</option>
                            <option value="hubungan">Hubungan</option>
                            <option value="keuangan">Keuangan</option>
                            <option value="kesehatan">Kesehatan</option>
                            <option value="lainnya">Lainnya</option>
                        </select>
                        <div v-if="FormData.topikKonseling === 'lainnya'" class="mt-2">
                            <label for="topikLain" class="form-label">Sebutkan Topik Lain:</label>
                            <input type="text" class="form-control" id="topikLain" v-model="FormData.topikLain" placeholder="Masukkan topik lainnya">
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="nomorWhatsapp" class="form-label">Counseling Services</label>
                        <select class="form-select" v-model="FormData.selectedCounselingService" id="nomorWhatsapp" required>
                            <option value="" disabled>Pilih Layanan Konseling</option>
                            <option value="psTorkis">Ps. Torkis Nababan</option>
                            <option value="ibuWenda">Ibu Wenda Poluan</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="pesanKonseling" class="form-label">Message</label>
                        <textarea class="form-control" id="pesanKonseling" v-model="FormData.pesan" rows="5" placeholder="Tuliskan pesan Anda" required></textarea>
                    </div>
                    <button type="submit" class="btn btn-danger">Send</button>
                </form>
            </div>
            <div v-if="currentTab === 'pelayanan'">
                <form @submit.prevent="submitPelayananForm" ref="pelayananForm">
                    <div class="mb-3">
                        <label for="pelayananNama" class="form-label">Full name</label>
                        <input type="text" class="form-control" id="pelayananNama" v-model="FormData.pelayananNama" placeholder="Nama Lengkap" required>
                    </div>
                    <div class="mb-3">
                        <label for="alamatPelayanan" class="form-label">Address</label>
                        <input type="text" class="form-control" id="alamatPelayanan" v-model="FormData.alamat" placeholder="Alamat" required>
                    </div>
                    <div class="mb-3">
                        <label for="noTelp" class="form-label">Phone Number</label>
                        <input type="tel" class="form-control" id="noTelp" v-model="FormData.noTelp" placeholder="No. Telefon" required>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Bidang Pelayanan</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-check" v-for="(item, index) in bidangPelayananOptions.slice(0, Math.ceil(bidangPelayananOptions.length / 2))" :key="index">
                                    <input class="form-check-input" type="checkbox" :id="item.id" :value="item.value" v-model="FormData.bidangPelayanan">
                                    <label class="form-check-label" :for="item.id">{{ item.label }}</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-check" v-for="(item, index) in bidangPelayananOptions.slice(Math.ceil(bidangPelayananOptions.length / 2))" :key="index">
                                    <input class="form-check-input" type="checkbox" :id="item.id" :value="item.value" v-model="FormData.bidangPelayanan">
                                    <label class="form-check-label" :for="item.id">{{ item.label }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="FormData.bidangPelayanan.includes('lainnya')">
                            <label for="bidangLain" class="form-label">Please Specify:</label>
                            <input type="text" class="form-control" id="bidangLain" v-model="FormData.bidangLain" placeholder="Bidang Lainnya">
                        </div>
                    </div>
                    <div class="mb-3">
                        <h5>Komitmen:</h5>
                        <ol>
                            <li>Mengikuti ibadah doa fajar (bila berhalangan wajib memberitahukan kepada koordinator)</li>
                            <li>Mengikuti ibadah raya Minggu</li>
                            <li>Mengikuti Latihan ibadah raya</li>
                        </ol>
                    </div>

                    <div>
                        <label for="tanggal" class="form-label">Date</label>
                        <input type="date" class="form-control" id="tanggal" v-model="FormData.tanggal" required>
                    </div>
                    <button type="button" class="btn btn-secondary" @click="printForm">Print</button>
                    <p>
                        <em class="textdesc">
                            * Setelah formulir ini dicetak, mohon untuk menyerahkannya kepada usher yang biasanya standby di depan pintu gereja. Terima kasih atas perhatian dan kerjasamanya.
                        </em>
                    </p>
                </form>
            </div>
        </div>
        <div class="parallax text-center mt-0" style="font-family: Arial, sans-serif;">
            <div class="overlay">
                <h3 class="text-light mb-3">Silahkan hubungi kami lebih lanjut</h3>
                <a href="mailto:gbimscic@gmail.com?subject=Pesan%20Kepada%20MSCIC&body=Shalom,%0D%0A%0D%0ASaya%20ingin%20menyampaikan%20beberapa%20hal%20kepada%20Gereja%20Mawar%20Saron%20Citra%20Indah%20City.%20Sebelumnya,%20perkenankan%20saya%20untuk%20memperkenalkan%20diri%20dan%20memberikan%20informasi%20berikut:%0D%0A%0D%0ANama:%0D%0AAlamat:%0D%0ANo.%20Telepon:%0D%0APesan:%0D%0A%0D%0ATerima%20kasih%20atas%20perhatian%20dan%20waktu%20yang%20diberikan.%0D%0ATuhan%20Yesus%20memberkati." class="btn modern-btn mt-2">
                    <i class="bi bi-envelope" style="font-size: 24px; margin-right: 10px;"></i>
                    Contact Us
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyContact",
    data() {
        return {
            currentTab: 'konseling', 
            FormData: {
                namaLengkap: '',
                alamat: '',
                nomorTelepon: '',
                pelayananNama: '',
                tanggal: this.formatDate(new Date()),
                bidangPelayanan: [], 
                bidangLain: '',
                pesan: '',
                nomorWhatsapp: '',
                topikKonseling: '',
                topikLain: ''
            },
            bidangPelayananOptions: [
                { id: 'singers', value: 'singers', label: 'Singers' },
                { id: 'pemainGitar', value: 'pemainGitar', label: 'Pemain Gitar' },
                { id: 'pemainBass', value: 'pemainBass', label: 'Pemain Bass' },
                { id: 'pemainKeyboard', value: 'pemainKeyboard', label: 'Pemain Keyboard' },
                { id: 'usher', value: 'usher', label: 'Usher' },
                { id: 'tamborine', value: 'tamborine', label: 'Tamborine' },
                { id: 'dancer', value: 'dancer', label: 'Dancer' },
                { id: 'choir', value: 'choir', label: 'Choir' },
                { id: 'videoEditing', value: 'videoEditing', label: 'Video Editing' },
                { id: 'liveStreaming', value: 'liveStreaming', label: 'Live Streaming' },
                { id: 'easyworship', value: 'easyworship', label: 'EasyWorship' },
                { id: 'soundman', value: 'soundman', label: 'Soundman' },
                { id: 'lighting', value: 'lighting', label: 'Lighting' },
                { id: 'lainnya', value: 'lainnya', label: 'Lainnya' }
            ]
        };
    },
    methods: {
        formatDate(date) {
            const year = String(date.getFullYear());
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const day = String(date.getDate()).padStart(2, '0'); 
            return `${day}-${month}-${year}`; 
        },

        submitKonselingForm() {
    if (!this.FormData.selectedCounselingService) {
        alert('Silahkan pilih Layanan Konseling.');
        return;
    }

    const subject = `Pengajuan Layanan Konseling`;
    const body = `Shalom,\n\nSaya ingin melakukan konseling dan berikut adalah data saya:\n\n` +
                `Nama: ${this.FormData.namaLengkap}\n` +
                `Alamat: ${this.FormData.alamat}\n` +
                `Topik Konseling: ${this.FormData.topikKonseling === 'lainnya' ? this.FormData.topikLain : this.FormData.topikKonseling}\n` +
                `Layanan Konseling: ${this.FormData.selectedCounselingService === 'psTorkis' ? 'Ps. Torkis Nababan' : 'Ibu Wenda Poluan'}\n` +
                `Pesan: ${this.FormData.pesan}\n\n` +
                `Terima kasih, Tuhan Yesus memberkati.`;
    const mailtoLink = `mailto:gbimscic@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
    this.resetKonselingForm();
    },

        resetKonselingForm() {
            this.FormData.namaLengkap = '';
            this.FormData.alamat = '';
            this.FormData.selectedCounselingService = '';
            this.FormData.topikKonseling = '';
            this.FormData.topikLain = '';
            this.FormData.pesan = '';
        },

        resetPelayananForm() {
            this.FormData.pelayananNama = '';
            this.FormData.alamat = '';
            this.FormData.noTelp = '';
            this.FormData.bidangPelayanan = [];
            this.FormData.bidangLain = '';
            this.FormData.tanggal = this.formatDate(new Date());
        },

        printForm() {
            if (!this.FormData.pelayananNama) { 
                alert('Full name cannot be empty');
                return;
            }
            if (!this.FormData.alamat) { 
                alert('Address cannot be empty');
                return;
            }
            if (!this.FormData.noTelp) { 
                alert('Phone number cannot be empty');
                return;
            }
            const logoPath = require('@/assets/mscic.png');
            const printContent = document.createElement('div');
            printContent.innerHTML = `
                <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px;">
                    <img src="${logoPath}" alt="Logo Mawar Saron" style="max-width: 100px;" />
                    <h2 style="margin: 0; text-align: center; flex-grow: 1;">FORMULIR PENDAFTARAN PELAYANAN</h2>
                </div>
                <h3 style="text-align: center;">GBI Mawar Saron Citra Indah</h3>

                 <p style="margin: 20px 0;">
                    <strong>NAMA &nbsp;:</strong> <span style="padding: 0 10px;">${this.FormData.pelayananNama}</span><br>
                    <strong>ALAMAT &nbsp;    :</strong> <span style="padding: 0 10px;">${this.FormData.alamat}</span><br>
                    <strong>NO.TELEFON &nbsp;:</strong> <span style="padding: 0 10px;">${this.FormData.noTelp}</span>
                </p>

                <p><strong>BIDANG PELAYANAN:</strong></p>
                <div style="display: flex; flex-wrap: wrap;">
                    ${this.bidangPelayananOptions.map(item => `
                        <label class="print-checkbox" style="width: calc(50% - 10px); margin-bottom: 10px;">
                            <input type="checkbox" ${this.FormData.bidangPelayanan.includes(item.value) ? 'checked' : ''} disabled />
                            ${item.label}
                        </label>
                    `).join('')}
                    <label class="print-checkbox" style="width: calc(50% - 10px); margin-bottom: 10px;">
                        <input type="checkbox" ${this.FormData.bidangPelayanan.includes('lainnya') ? 'checked' : ''} disabled />
                        Lainnya: <span style="border-bottom: 1px solid #000; padding: 0 10px;">${this.FormData.bidangPelayanan.includes('lainnya') ? this.FormData.bidangLain : ''}</span>
                    </label>
                </div>
                <p><strong>KOMITMEN:</strong></p>
                <ol>
                    <li>MENGIKUTI IBADAH DOA FAJAR (Bila berhalangan wajib memberitahukan kepada Koordinator)</li>
                    <li>MENGIKUTI IBADAH RAYA MINGGU</li>
                    <li>MENGIKUTI LATIHAN IBADAH RAYA (Tim Worship)</li>
                </ol>

                <p style="margin-top: 30px">Citra Indah, ${this.formatDate(new Date(this.FormData.tanggal))}</p>
                <div style="display: flex; justify-content: space-between; margin-top: -5px;">
                    <span>Yang mendaftar,</span>
                    <span style="margin-right: 50px;">Koordinator,</span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: 50px;">
                    <span>(..............................)</span>
                    <span style="margin-right: 35px;">(..............................)</span>
                </div>
            `;
            const originalContent = document.body.innerHTML; 
            document.body.innerHTML = printContent.innerHTML; 
            window.print(); 
            document.body.innerHTML = originalContent; 
            window.location.reload();
        }
    }
};
</script>

<style scoped>
.contact {
    background-color: #f0f0f0;
}

.container {
    padding-top: 80px;
    max-width: 800px;
    margin: 0 auto;
}

.nav-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif
}

.nav-item {
    margin: 0 15px;
}

.nav-link {
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
    color: #dc3545;
    transition: all 0.3s ease;
    background-color: #cfcfcf;
}

.nav-link:hover {
    background-color: #dc3545;
    color: white;
}

.nav-item .nav-link {
    cursor: pointer;
}

.nav-item .nav-link.active {
    background-color: #d9534f;
    color: white;
}

.form-label {
    font-weight: bold;
}

.form-control, 
.form-select {
    border-radius: 5px;
}

.btn-secondary {
    background-color: #6c757d;
    border: none;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.form-label {
    font-weight: bold;
}

.description-container {
    max-width: 2000px;
    margin: 0 auto;
    padding: 10px 20p;
}

.description { 
    text-align: justify;
    font-size: 1.1rem;
    color: #6c757d;
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}

.textdesc { 
    text-align: justify;
    font-size: 14px;
    color: rgb(255, 48, 48);
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif;
}

.text-center {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    color:#dc3545;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
    margin: 20px 0;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

button {
    width: 100%;
    padding: 10px;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.form-check-input:checked {
    background-color: red;
    border-color: red;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.parallax {
    background-image: url(@/assets/contact.png);
    height: 350px; 
    background-attachment: fixed; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    position: relative;
    color: white; 
}

.overlay {
    padding: 20px;
    border-radius: 10px; 
}

.modern-btn {
    background-color: #ff0019; 
    color: white;
    border: none;
    border-radius: 25px; 
    padding: 10px 20px;
    font-size: 18px;
    text-transform: uppercase; 
    transition: background-color 0.3s, transform 0.3s; 
}

.modern-btn:hover {
    background-color: #970211; 
    transform: scale(1.05); 
}

.text-light {
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 
}

@media print { 
    body {
        margin: 0;
        padding: 20px;
        font-family: Arial, sans-serif;
        font-size: 12pt;
    }

    h2, h3 {
        margin: 0;
    }

    #printableArea {
        width: 100%;
    }

    input[type="checkbox"] {
        margin-right: 10px;
        transform: scale(1.5);
    }

    strong {
        display: inline-block;
        width: 120px;
    }

    .no-print { 
        display: none;
    }

    .contact .nav-tabs, 
    .contact .description-container, 
    .contact h2 {
        display: none;
    }

    .contact form{
        display: block;
    }

    .print-checkbox input:checked {
        background-color: red;
    }

    .print-checkbox input:checked + label {
        color: red;
    }
}

@media (max-width: 768px) { 
    .nav-tabs {
        flex-direction: column;
    }

    .nav-item {
        margin: 0;
        margin-bottom: 10px;
        text-align: center;
    }

    .btn {
        width: 100%;
    }

    .text-light {
    font-size: 30px;
    }
}
</style>