import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import Gms from './views/Gms.vue';
import Msw from './views/Msw.vue';
import Media from './views/Media.vue';
import Contact from './views/Contact.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/gms', component: Gms },
  { path: '/msw', component: Msw },
  { path: '/media', component: Media },
  { path: '/contact', component: Contact },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
