<template> 
    <div class="container">
        <div class="coming-soon">COMING SOON</div>
        <div class="church-name">Graha Mawar Saron</div>
    </div>
    <div class="history">
        <h2>History</h2>
        <div class="text-content">
            <p>Dengan pertumbuhan jemaat yang sangat cepat serta Visi-Misi gereja untuk jiwa-jiwa, kami memutuskan untuk membeli sebidang tanah di Perumahan Citra Indah City.</p>
            <p>Tanah yang akan kami beli merupakan sebidang tanah terakhir yang bisa dibangun untuk Gedung Gereja di Perumahan Citra Indah City. Dengan luas tanah 2450 m2, kami hanya akan membayar untuk tanah seluas 1634 m2.</p>
            <p>Sisa tanah seluas 816 m2 dihibahkan oleh (Alm) Ir. Ciputra sebagai Founder Ciputra Group kepada GBI Mawar Saron Citra Indah. Dengan anugerah dari Tuhan, harga tanah yang semula Rp 2.500.000 per m2 diturunkan menjadi Rp 800.000 per m2</p>
            <p>Oleh karena itu, dengan pertimbangan yang matang, kami bersepakat untuk membeli sebidang tanah tersebut untuk kami bangun Gedung Graha Mawar Saron di atasnya. Kami yakin dan percaya, dengan kesatuan hati dari para Jemaat, Pengerja, Gembala Sidang, serta para Donatur, tanah tersebut bisa kami beli dan kami bangun Gedung Graha Mawar Saron di atas lahan tersebut</p>
            <p>Pembelian lahan dan pembangunan Gedung Graha Mawar Saron memang sudah sangat mendesak, mengingat kondisi Gedung Gereja yang sekarang kami pakai untuk beribadah sudah tidak layak dan gedung tersebut merupakan milik Pemda Kabupaten Bogor. Ketika lahan tersebut sudah kami beli dan bangun Gedung Graha Mawar Saron di atasnya, biarlah Nama Tuhan yang ditinggikan dan dimuliakan.</p>
            <p>Tuhan Yesus memberkati kita semua.</p>
        </div>
    </div>
    <div class="history-container">
        <div id="historyCarousel" class="carousel slide carousel-custom" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/old.png" class="d-block w-100 history-image" alt="Gereja Lama">
                    <div class="carousel-caption">
                            <h5>Gedung gereja yang digunakan untuk ibadah saat ini</h5>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="@/assets/new.png" class="d-block w-100 history-image" alt="Gereja Baru">
                    <div class="carousel-caption">
                        <h5>Lokasi tanah yang dibeli, bersebelahan dengan PT. Belfoods Indonesia</h5>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#historyCarousel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#historyCarousel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <div class="video-container">
            <video width="100%" height="450" controls>
                <source src="@/assets/design.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <p class="video-caption">Design Pembangunan Graha Mawar Saron</p>
        </div>
    </div>
</template>

<script>
export default { 
    name: 'MyGms'
}
</script>

<style scoped>
.container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: 1600px;
    background-image: url('../assets/gmsbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.container::before { 
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.coming-soon { 
    font-family: 'Poppins', sans-serif;
    color: red; 
    font-size: 8rem;
    font-weight: bold;
    text-align: center;
    animation: blink 1s infinite;
    position: relative;
    z-index: 2;
}

.church-name { 
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #f5f5f5;
    font-size: 7rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    position: relative;
    z-index: 2;
    animation: fadeIn 2s ease-in;
}

.history-container { 
    display: flex;
    gap: 20px;
    padding: 30px;
    position: relative;
    overflow: hidden;
}

.history-container::before { 
    content: '';
    background-image: url('../assets/gmsbgg.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    z-index: -s1;
}

.history { 
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    max-width: 1250px;
    text-align: justify;
    z-index: 2;
    padding: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
}

.history h2 { 
    font-family: 'Poppins', sans-serif;
    color: #d32f2f;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 20px;
}

.history p { 
    font-family: 'Cormorant Garamond', serif;
    color: #333;
    font-size: 1.15rem;
    line-height: 1.75;
    margin-bottom: 20px;
}

.text-content:hover { 
    transform: translateY(-10px);
    transition: transform 0.3s ease-in-out;
}

.history-image {
    max-height: 90vh; 
    max-width: 70%; 
    width: auto; 
    object-fit: cover; 
    border-radius: 10px;
    margin: 0 auto; 
}

.carousel-custom { 
    width: 50%;
}

.carousel-caption h5 {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    font-size: 30px;
}

.video-container {
    margin-top: 5%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.video-caption { 
    font-family: 'Montserrat', sans-serif;
    color: #fbfbfb;
    margin-top: 10px;
    font-size: 25px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}

@keyframes blink { 
    0%, 100% { 
        opacity: 1;
    }
    50% { 
        opacity: 0;
    }
}

@keyframes fadeIn { 
    0% {
        opacity: 0;
    }
    100% { 
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .container {
        margin-top: 20%;
        height: auto; 
        padding: 0px;
        overflow-x: hidden;
        max-width: 100vw;
    }

    .coming-soon {
        margin-top: 10%;
        font-size: 3.1rem; 
    }

    .church-name {
        margin-top: -5%;
        font-size: 5rem; 
    }

    .history-container {
        display: flex;
        overflow-x: hidden;
        flex-direction: column; 
        width: 100%;
    }

    .history {
        max-width: 100%; 
        padding: 20px;
        margin: 20px auto;
    }

    .history h2 {
        font-size: 2.5rem; 
    }

    .history p {
        font-size: 1rem; 
    }

    .history-image {
        max-height: 50vh;
        height: 100vh; 
        width: 100%;
        overflow: hidden;
    }

    .carousel-custom {
        width: 100%; 
    }

    .carousel-caption h5 { 
        font-size: 10px;
    }

    .video-container {
        margin-top: -30%;
        width: 100%; 
        overflow: hidden;
    }

    .video-caption {
        margin-top: -30%;
        font-size: 15.5px; 
    }
}
</style>