<template>
    <div>
        <ul class="nav nav-tabs d-flex justify-content-end">
            <li class="nav-item">
                <a class="nav-link" :class="{ active: activeTab === 'live'}" @click="activeTab = 'live'" href="#">Live Streaming</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ active: activeTab === 'social'}" @click="openSocialMedia" href="#">Social Media</a>
            </li>
        </ul>
        <div class="tab-content">
            <div v-if="activeTab === 'live'" class="tab-pane fade show active">
                <div class="live-streaming-page d-flex align-items-center justify-content-center">
                    <div class="computer card">
                        <div class="screen">
                            <img src="../assets/live.png" alt="Poster" class="live-image" />
                        </div>
                        <div class="base"></div>
                    </div>
                    <div class="countdown">
                        <h3>Live Streaming akan dimulai dalam:</h3>
                        <div class="timer">
                            <div class="time-box">
                                <div>{{ days }}</div>
                                <div class="label">Hari</div>
                            </div>
                            <div class="time-box">
                                <div>{{ hours }}</div>
                                <div class="label">Jam</div>
                            </div>
                            <div class="time-box">
                                <div>{{ minutes }}</div>
                                <div class="label">Menit</div>
                            </div>
                            <div class="time-box">
                                <div>{{ seconds }}</div>
                                <div class="label">Detik</div>
                            </div>
                        </div>
                        <div class="social-buttons mt-3">
                            <button @click="openYoutube" class="btn btn-danger me-2">
                                <i class="bi bi-youtube"></i>Watch on Youtube
                            </button>
                            <button @click="openFacebook" class="btn btn-primary">
                                <i class="bi bi-facebook"></i>Watch on Facebook
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeTab === 'social'" class="tab-pane fade"></div>
        </div>
        <div class="posters mt-4">
            <div v-for="poster in paginatedPosters" :key="poster.title" class="poster-card">
                <img :src="poster.image" :alt="poster.title" @click="openPosterLink(poster.link)" class="poster-image" />
                <div class="poster-title">{{ poster.title }}</div>
            </div>
        </div>
        <nav aria-label="Page navigation example" class="mt-4">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 0 }">
                    <a class="page-link" href="#" @click.prevent="prevPage" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li v-for="(page, index) in totalPages" :key="index" class="page-item" :class="{ active: index === currentPage }">
                    <a class="page-link" href="#" @click.prevent="setPage(index)">{{ index + 1 }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages - 1 }">
                    <a class="page-link" href="#" @click.prevent="nextPage" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'MyMedia',
    data() { 
        return { 
            activeTab: 'live', 
            currentPage: 0, 
            itemsPerPage: 4, 
            posters: [
                { title: "Ibadah Minggu", image: require('@/assets/poster10.png'), link: "https://www.youtube.com/live/I1zTLoUBuNw?si=XLsxXQPUG0OOPnju" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster9.png'), link: "https://www.youtube.com/live/xyaufaF0ktg?si=Pbldz17O2oGsOatd" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster1.png'), link: "https://www.youtube.com/live/GmVTg12T_Kw?si=NI1_jvDqzf251R0C" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster2.png'), link: "https://www.youtube.com/live/kMSxXPdxwDE?si=qJFrdY2XJyvXe1xG" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster3.png'), link: "https://www.youtube.com/live/-qbhetFh8_4?si=WlKZLUtj3c5tWKwo" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster4.png'), link: "https://www.youtube.com/live/MO68NFaVHQU?si=WT8HSsR1qUT1ABM1" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster5.png'), link: "https://www.youtube.com/live/x7U2PDoJiRQ?si=ijCnhxRtLTvo0C8A" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster6.png'), link: "https://www.youtube.com/live/6Ypd3g8Nwqg?si=EEJZxooAoN9gm3Dm/" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster7.png'), link: "https://www.youtube.com/live/w1ALXrSUN7w?si=qu9p_Kl656Rm0KHk" }, 
                { title: "Ibadah Minggu", image: require('@/assets/poster8.png'), link: "https://www.youtube.com/live/MO68NFaVHQU?si=8eb2jST1S2zRt05S" }, 
            ],
            days: 0,
            hours: 0, 
            minutes: 0, 
            seconds: 0
        };
    }, 
    created() { 
        this.startCountdown();
    },
    computed: {
        totalPages() {
            return Math.ceil(this.posters.length / this.itemsPerPage);
        },
        paginatedPosters() {
            const start = this.currentPage * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.posters.slice(start, end);
        }
    },
    methods: {
        openSocialMedia() { 
            window.open('https://media.gbimscic.com', '_blank'); 
        },
        openYoutube() { 
            window.open('https://www.youtube.com/@mawarsaroncitratv7584/streams', '_blank');
        },
        openFacebook() { 
            window.open('https://www.facebook.com/gbimawarsaron.citraindah/', '_blank');
        },
        setPage(page) {
            this.currentPage = page;
        },
        prevPage() {
            if (this.currentPage > 0) this.currentPage--;
        },
        nextPage() {
            if (this.currentPage < this.totalPages - 1) this.currentPage++;
        },
        openPosterLink(link) {
            window.open(link, '_blank');
        },
        startCountdown() { 
            const liveStreamDate = new Date('2024-11-17T07:00:00'); 
            const updateCountdown = () => { 
                const now = new Date();
                const timeDiff = liveStreamDate - now;

                if(timeDiff <= 0) { 
                    this.countdown = 'Live Streaming Dimulai!'; 
                    clearInterval(this.interval); 
                    this.days = 0; 
                    this.hours = 0;
                    this.minutes = 0;
                    this.seconds = 0;
                    return; 
                }

                this.days = Math.floor(timeDiff / ( 1000 * 60 * 60 * 24)); 
                this.hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); 
                this.minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((timeDiff % (1000 * 60))/ 1000);
            }; 
            updateCountdown(); 
            this.interval = setInterval(updateCountdown, 1000); 
        }
    }, 
    beforeUnmount() { 
        clearInterval(this.interval); 
    }
}
</script>

<style scoped>
.nav { 
    margin-top: 95px;
}

.nav-link {
    font-weight: bold; 
    background-color: white;
    color: red;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border: 1px solid transparent;
    padding: 10px 20px;
}

.nav-link:hover { 
    color: whitesmoke;
    background-color: red;
    transform: scale(1.05);
}

.nav-link.active { 
    background-color: darkred !important;
    color: white !important;
}

.live-streaming-page { 
    background-color: darkred;
    height: 100vh;
}

.computer { 
    margin-top: -120px;
    width: 70%;
    max-width: 860px;
    background-color: black; 
    border-radius: 10px;
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.screen { 
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.live-image { 
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: contain;
}

.base { 
    width: 60%;
    height: 20px;
    background-color: #222;
    margin: 0 auto;
    border-radius: 5px;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.countdown { 
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-left: 40px;
    margin-top: -100px;
    color: white;
}

.timer { 
    margin-top: 25px;
    display: flex;
    gap: 10px;
    justify-content: center;
    font-family: 'Courier New', Courier, monospace;
}

.timer .time-box { 
    background-color: #222;
    color: #00ff88;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    font-size: 2.5rem;
    min-width: 70px;
    text-align: center;
}

.timer .label { 
    font-size: 0.8rem;
    color: #aaa;
    margin-top: 8px;
    text-transform: uppercase;
}

.social-buttons .btn { 
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.posters { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.poster-card { 
    background-color: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    position: relative;
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.poster-card:hover { 
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.poster-image { 
    width: 100%;
    height: auto;
}

.poster-title { 
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: #333;
    font-size: 1.1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    opacity: 0.8;
    transition: opacity 0.3s;
    width: 100%;
}

.poster-card:hover .poster-title { 
    opacity: 1;
}

.pagination .page-item.active .page-link { 
    background-color: #ff0000;
    border-color: #ff0000;
}

@media (max-width: 768px) {
    .nav { 
        margin-top: 85px;
    }

    .live-streaming-page {
        flex-direction: column;
        align-items: center;
        height: 85vh;
    }

    .computer {
        margin-top: 1px;
        margin-bottom: 40px;
        width: 95%;
    }

    .countdown {
        margin: 0;
    }

    .countdown h3 { 
        font-size: 20px;
    }

    .timer .time-box {
        padding: 10px 8px;
        font-size: 1.8rem;
    }

    .social-buttons .btn {
        padding: 10px 15px;
        font-size: 0.9rem;

    }

    .poster-card { 
        border-radius: 0;
        width: 380px;
    }
}
</style>
