<template>
    <nav class="navbar fixed-top navbar-expand-lg" id="navbar">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="@/assets/mscic.png" alt="Logo MSCIC" class="logo">
            </a>
            <button class="navbar-toggler" type="button" @click="toggleNavbar" aria-controls="navbarNavAltMarkup" aria-expanded="isNavbarVisible" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" :class="{ show: isNavbarVisible }" id="navbarNavAltMarkup">
                <div class="navbar-nav ms-auto">
                    <router-link class="nav-link" to="/" @click="hideNavbar">Home</router-link>
                    <router-link class="nav-link" to="/gms" @click="hideNavbar">Graha Mawar Saron</router-link>
                    <router-link class="nav-link" to="/msw" @click="hideNavbar">Ms Worship</router-link>
                    <a class="nav-link" href="https://ibadah.gbimscic.com" target="_blank" @click="hideNavbar">Ibadah & Komsel</a>
                    <router-link class="nav-link" to="/media" @click="hideNavbar">Media</router-link>
                    <router-link class="nav-link" to="/contact" @click="hideNavbar">Contact Us</router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "MyNavbar",
    data() {
        return {
            isNavbarVisible: false,
        };
    },
    methods: {
        toggleNavbar() {
            this.isNavbarVisible = !this.isNavbarVisible;
        },
        hideNavbar() {
            this.isNavbarVisible = false;
        }
    }, 
    watch: { 
        $route() { 
            window.scrollTo(0,0);
        }
    }
};
</script>

<style scoped>
#navbar {
    background: rgba(255, 255, 255, 0.9); 
    backdrop-filter: blur(10px); 
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    z-index: 1000;
    padding: 15px 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-brand .logo {
    width: 150px; 
    height: auto;
}

.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navbar-nav .nav-link {
    color: #333; 
    font-weight: 500;
    transition: color 0.3s ease, transform 0.3s ease;
    font-family: 'Lucida Sans', 'Lucida Grande', 'Verdana', sans-serif;
    margin: 0 10px;
}

.navbar-nav .nav-link:hover {
    color: red; 
    transform: scale(1.1); 
}

.navbar-nav .nav-link.router-link-active,
.navbar-nav .nav-link.router-link-exact-active {
    color: red; 
    font-weight: bold; 
    border-bottom: 2px solid red; 
    transform: none; 
}

.navbar-toggler {
    border: none; 
    background-color: transparent;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='red' height='30' width='30' viewBox='0 0 30 30'%3E%3Cpath stroke='red' stroke-width='3' d='M 5 7 L 25 7 M 5 15 L 25 15 M 5 23 L 25 23' /%3E%3C/svg%3E");
}

@media (max-width: 768px) {
    .navbar {
        padding: 10px; 
    }

    .navbar-brand .logo {
        width: 120px; 
    }

    .navbar-nav {
        flex-direction: column; 
        margin-top: 10px; 
    }

    .navbar-nav .nav-link {
        margin: 5px 0;
    }

    .navbar-nav .nav-link.router-link-active,
    .navbar-nav .nav-link.router-link-exact-active {
        border-bottom: 2px solid red; 
    }
}
</style>