<template>
  <div id="app">
    <Navbar />
    <router-view></router-view>
    <Persembahan />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Persembahan from './components/Persembahan.vue'
import Footer from './components/Footer.vue';

export default{ 
  name: 'App',
  components: {
    Navbar,
    Persembahan,
    Footer
  }
};
</script>