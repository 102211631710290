<template>
    <div class="msw">
        <video autoplay muted loop class="video-bg">
            <source src="@/assets/mscicnews.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
    <div class="image-carousel">
        <div class="carousel-track" @mouseover="pausedScroll" @mouseleave="resumeScroll" :style="{ animationPlayState: isPaused ? 'paused' : 'running'}">
            <div class="image-item" v-for="(image, index) in images" :key="index">
                <img :src="image.src" :alt="'Image ' + (index + 1)" /> 
            </div>
        </div>
    </div>
    <div class="row section-content">
        <div class="col-lg-6 col-md-12 parallax-section">
            <div class="parallax-image">
                <div class="parallax-overlay"></div>
                <div class="parallax-text">
                    <h2>Welcome to Ms Worship</h2>
                    <p>MS Worship adalah program live streaming yang menyajikan pujian, penyembahan, serta sharing Firman Tuhan dari GBI Mawar Saron Citra Indah City (MSCIC) Jonggol. Program ini dihadirkan dengan harapan dapat menjadi berkat bagi para pemirsa di mana pun mereka berada, melalui media online yang dapat diakses dengan mudah.</p>
                    <p>Saksikan MS Worship setiap Rabu, Pukul 19.00 : </p>
                    <div class="social-buttons">
                        <a href="https://www.youtube.com/@mawarsaroncitratv7584/streams" class="btn btn-youtube" target="_blank">
                            <i class="bi bi-youtube"></i> Live Streaming Youtube 
                        </a>
                        <a href="https://www.facebook.com/gbimawarsaron.citraindah/" class="btn btn-facebook" target="_blank">
                            <i class="bi bi-facebook"></i> Live Streaming Facebook
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 schedule-section">
            <div class="wrapper-video">
                <video autoplay muted loop class="bg-video">
                    <source src="@/assets/msw.mp4" type="video/mp4">
                    Your browser does not support the video tag. 
                </video>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <h2 class="text-center mb-4">Our Products</h2>
        <div class="row">
            <div class="col-lg-4 col-md-6 mb-4" 
                v-for="(product, index) in products" 
                :key="index" 
                :class="{
                    'centered-product': isCenteredProduct(index),
                    'middle-start': index === 3 || index === 4 
                }">
                <div class="product-wrapper position-relative">
                    <img :src="require(`@/assets/${product.image}`)" :alt="product.name" class="img-fluid product-image">
                    <div class="overlay d-flex align-items-center justify-content-center">
                        <template v-if="isMSWorshipProduct(product.name)">
                            <h3 class="text-white">
                                <img :src="require('@/assets/msworship.png')" alt="MS Worship Logo" class="msw-logo" /> 
                                {{ product.name.replace('MS Worship', '').trim() }}
                            </h3>
                        </template>
                        <template v-else>
                            <h3 class="text-white">{{ product.name }}</h3>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="parallax text-center" style="font-family: Arial, sans-serif;">
        <video autoplay muted loop class="video-background">
            <source src="@/assets/mswc.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="over">
            <h3 class="text-light">Silahkan hubungi kami lebih lanjut</h3>
            <a href="mailto:gbimscic@gmail.com?subject=Permintaan%20Informasi%20Ms%20Worship&body=Shalom,%0D%0A%0D%0ASaya ingin tau lebih lanjut terkait Program MS Worship. Berikut ini adalah data saya.%0D%0A%0D%0ANama:%0D%0AAlamat:%0D%0ANomor Telefon:%0D%0APesan:%0D%0A%0D%0AGod Bless You!" class="btn modern-btn mt-2" target="_blank">
                <i class="bi bi-envelope" style="font-size: 24px; margin-right: 15px;"></i>Contact Us
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyMsw',
    data() {
        return {
            products: [
                { name: 'MS Worship Catalog', image: 'catalog1.png' },
                { name: 'MS Worship Men t-shirt', image: 'catalog2.png' },
                { name: 'MS Worship Women t-shirt', image: 'catalog3.png' },
                { name: 'MS Worship Tumbler, Keychain, Sticker', image: 'catalog4.png' },
                { name: 'MS Worship Mug', image: 'catalog5.png' },
            ], 
            images: [
                {src: require('@/assets/msw7.png')},
                {src: require('@/assets/msw2.png')},
                {src: require('@/assets/msw3.png')},
                {src: require('@/assets/msw4.png')},
                {src: require('@/assets/msw5.png')},
                {src: require('@/assets/msw6.png')},
                {src: require('@/assets/msw1.png')},
                {src: require('@/assets/msw8.png')},
                {src: require('@/assets/msw2.png')},
                {src: require('@/assets/msw3.png')},
                {src: require('@/assets/msw4.png')},
                {src: require('@/assets/msw5.png')},
                {src: require('@/assets/msw6.png')},
                {src: require('@/assets/msw1.png')},
            ],
            isPaused: false, 
        };
    },
    methods: {
        isMSWorshipProduct(productName) {
            return productName.includes('MS Worship');
        },
        isCenteredProduct(index) {
            return index === 3 || index === 4;
        }, 
        pausedScroll() {
            this.isPaused = true; 
        }, 
        resumeScroll() {
            this.isPaused = false;
        },
    }, 
};
</script>

<style scoped>
.msw { 
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-top: 6.2%;
}

.video-bg { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.image-carousel { 
    margin-top: 20px;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-track { 
    display: flex;
    justify-content: flex-start;
    animation: scroll 10s linear infinite; 
}

.image-item { 
    width: 15%;
    flex-shrink: 0;
    text-align: center;
    position: relative;
    margin-right: 30px;
}

.image-item img { 
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
}

.carousel-track:hover { 
    animation-play-state: paused;
}

.image-item:hover img {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.text-center {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: #dc3545;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 20px 0;
}

.section-content { 
    width: 100%;
    max-width: 100vw;
    margin: 0;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.parallax-section { 
    position: relative;
    overflow: hidden;
    padding: 0;
    height: 60vh;
}

.parallax-image { 
    background-image: url(@/assets/mswp.png);
    background-attachment: fixed;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.parallax-overlay { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0;
}

.parallax-text { 
    font-family: 'Montserrat', sans-serif ;
    color: white;
    text-align: left;
    position: relative;
    z-index: 1;
    padding: 20px;
    border-radius: 10px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
}

.social-buttons .btn { 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin: 5px;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.btn-youtube { 
    background-color: #ff0000;
}

.btn-facebook { 
    background-color: #3b5998;
}

.btn-youtube:hover { 
    background-color: #cc0000;
}

.btn-facebook:hover { 
    background-color: #2d4373;
}

.btn i { 
    margin-right: 8px;
}

.schedule-section { 
    position: relative;
    background-color: white;
    height: 60vh;
    overflow: hidden;
}

.wrapper-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.bg-video { 
    width: 100%;
    height: 100;
    object-fit: cover;
}

.product-wrapper {
    overflow: hidden;
    position: relative;
}

.product-image {
    transition: transform 0.4s ease;
    border-radius: 10px;
    width: 100%;
}

.product-wrapper:hover .product-image {
    transform: scale(1.1);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.4s ease;
    border-radius: 10px;
}

.product-wrapper:hover .overlay {
    opacity: 1;
}

.overlay h3 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    display: flex;
    align-items: center;
}

.msw-logo {
    width: 100px;
    margin-right: 8px; 
}

.centered-product {
    display: flex;
    justify-content: center;
    flex-direction: column; 
    align-items: center; 
}

.middle-start {
    margin-left: auto;
    margin-right: auto;
}

.middle-start:nth-child(4),
.middle-start:nth-child(5) {
    margin-right: -200px;
    margin-left: 200px;
}

.parallax { 
    height: 500px;
    position: relative;
    width: 100%; 
    margin: 0;
    padding: 0; 
    overflow: hidden;
}

.video-background { 
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
    transform: translate(-50%, -50%);
}

.over { 
    padding: 20px;
    border-radius: 10px; 
    position: relative;
    z-index: 2;
}

.modern-btn {
    background-color: #ff0019; 
    color: white;
    border: none;
    border-radius: 25px; 
    padding: 10px 20px;
    font-size: 18px;
    text-transform: uppercase; 
    transition: background-color 0.3s, transform 0.3s; 
}

.modern-btn:hover {
    background-color: #970211; 
    transform: scale(1.05); 
}

.text-light {
    margin-top: 180px;
    margin-bottom: 15px;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

@keyframes scroll { 
    0% { 
        transform: translateX(0);
    }
    100% { 
        transform: translateX(-100%);
    }
}

@media (max-width: 768px) { 
    .msw { 
        height: 75vh;
    }

    .image-item {
        width: 100%;
        margin-right: -190px
    }

    .image-item img {
        width: 50%;
    }

    .parallax-section, .schedule-content { 
        height: auto;
    }

    .schedule-section { 
        display: none;
    }

    .middle-start:nth-child(4),
    .middle-start:nth-child(5) {
        margin-right: auto;
        margin-left: auto;
    }

    .parallax-image { 
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }

    .text-light {
    font-size: 30px;
    }
}
</style>